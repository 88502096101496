type IconProps = {
  title: string;
  color: string;
};

export const Github = ({ title, color }: IconProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <g fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M2.497 0h45.006A2.494 2.494 0 0 1 50 2.497v45.006A2.494 2.494 0 0 1 47.503 50H2.497A2.494 2.494 0 0 1 0 47.503V2.497A2.494 2.494 0 0 1 2.497 0zm18.65 39.751c0-.379-.013-1.38-.02-2.712-4.325.963-5.237-2.137-5.237-2.137-.707-1.841-1.727-2.332-1.727-2.332-1.411-.989.107-.968.107-.968 1.56.112 2.381 1.643 2.381 1.643 1.387 2.435 3.639 1.733 4.525 1.325.141-1.03.543-1.734.987-2.132-3.452-.402-7.082-1.77-7.082-7.878 0-1.74.607-3.163 1.601-4.278-.16-.403-.694-2.023.153-4.218 0 0 1.305-.429 4.274 1.634 1.24-.353 2.57-.531 3.893-.537 1.32.006 2.65.184 3.892.537 2.968-2.063 4.271-1.634 4.271-1.634.849 2.194.315 3.814.155 4.218.996 1.115 1.598 2.538 1.598 4.278 0 6.124-3.636 7.471-7.1 7.865.56.493 1.056 1.465 1.056 2.953 0 2.131-.02 3.85-.02 4.373 0 .427.28.922 1.07.766 6.172-2.111 10.623-8.083 10.623-15.123 0-8.804-6.962-15.94-15.547-15.94S9.453 16.59 9.453 25.393c.001 7.042 4.456 13.017 10.633 15.125.777.146 1.062-.346 1.062-.768z"
      />
    </g>
  </svg>
);
