import { Layout } from '@/components/Layout';

export const About = () => (
  <Layout title="About">
    <p>
      I've been a Front End developer since 2011 and during that time had the
      opportunity to work with many different types of tech within many
      different areas of business.
    </p>
    <p>
      After working in advertising, education, consultancy and fashion, I've
      enjoyed all different kinds of challenges these areas have thrown at me.
    </p>
    <p>
      During this time, I've also had the pleasure to take a swing at leadership
      by becoming an Engineering Lead for a couple of years, definitely a career
      I'm looking forward to pursue.
    </p>
    <p>
      I had the opportunity to meet, learn and mentor some great people and work
      in many different areas.
    </p>
    <p>
      If you do have any questions, get in touch. I'd love to talk to you about
      it.
    </p>
  </Layout>
);
