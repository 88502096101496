type IconProps = {
  title: string;
  color: string;
};

export const Codepen = ({ title, color }: IconProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      fill={color}
      fillRule="nonzero"
      d="M45.833 0A4.179 4.179 0 0 1 50 4.167v41.666A4.179 4.179 0 0 1 45.833 50H4.167A4.179 4.179 0 0 1 0 45.833V4.167A4.179 4.179 0 0 1 4.167 0h41.666zM41 19.754v-.105-.105-.105-.105-.106s0-.105-.104-.105v-.105l-.104-.105h-.104l-.104-.105h-.104L26.228 9.237a1.239 1.239 0 0 0-1.456 0l-14.252 9.57h-.104l-.104.106h-.104l-.104.105v.105s0 .105-.104.105V30.693s0 .105.104.105v.105l.104.105.104.105.104.106h.104l14.252 9.57c.208.106.52.211.728.211.208 0 .52-.105.728-.21l14.252-9.571h.104s.104 0 .104-.106l.104-.105.104-.105v-.105s0-.105.104-.105v-.105-.106-.105-.105-.105-.105-.106-9.781-.316-.105zm-15.604 8.31l-4.785-3.156 4.785-3.155 4.785 3.155-4.785 3.155zm-1.352-8.73l-5.826 3.891-4.681-3.155 10.507-7.047v6.31zm-8.218 5.574l-3.33 2.314v-4.523l3.33 2.209zm2.392 1.578l5.826 3.891v6.31l-10.507-7.046 4.681-3.155zm8.53 3.996l5.826-3.891 4.681 3.155-10.507 7.047v-6.31zm8.218-5.574l3.33-2.314v4.523l-3.33-2.209zm-2.392-1.683l-5.826-3.891v-6.311l10.507 7.047-4.681 3.155z"
    />
  </svg>
);
